<template>
  <div>
    <navs></navs>
    <consult></consult>
    <img
      :src="img[3].picture"
      alt=""
      @click="tzviewwap"
      class="header_img"
      v-if="img.length > 0"
    />
    <div class="content">
      <div class="title">
        <div class="d"></div>
        <div>职业技能</div>
      </div>
      <div class="list">
        <router-link to="/instrumentdetails?id=1" class="item">
          <img src="../assets/a1.png" class="tuo" />
          <div style="width: 1.3rem; margin: 0 auto">成绩查询</div>
        </router-link>
        <router-link to="/instrumentdetails?id=2" class="item">
          <img src="../assets/a3.png" class="tuo" />
          <div style="width: 1.3rem; margin: 0 auto">证书查询</div>
        </router-link>
        <router-link to="/examination" class="item">
          <img src="../assets/a6.png" class="tuo" />
          <div style="width: 1.3rem; margin: 0 auto">考试申报</div>
        </router-link>
        <router-link to="/instrumentdetails?id=4" class="item">
          <img src="../assets/a2.png" class="tuo" />
          <div style="width: 1.3rem; margin: 0 auto">领取补贴<br />攻略</div>
        </router-link>
        <router-link to="/instrumentdetails?id=6" class="item">
          <img src="../assets/a4.png" class="tuo" />
          <div style="width: 1.3rem; margin: 0 auto">个税抵扣<br />攻略</div>
        </router-link>
        <router-link to="/instrumentdetails?id=7" class="item">
          <img src="../assets/a5.png" class="tuo" />
          <div style="width: 1.3rem; margin: 0 auto">
            浦东补贴<br />培训绑定
          </div>
        </router-link>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <div class="d"></div>
        <div>居住证积分落户</div>
      </div>
      <div class="list">
        <router-link class="item" to="/integrating">
          <img src="../assets/b1.png" class="tuo" />
          <div style="width: 150rpx; margin: 0 auto">
            居住证积分<br />模拟打分
          </div>
        </router-link>
        <router-link class="item" to="/rzhuanhu">
          <img src="../assets/b2.png" class="tuo" />
          <div style="width: 150rpx; margin: 0 auto">居转户条件<br />评定</div>
        </router-link>
        <router-link class="item" to="/instrumentdetails?id=9">
          <img src="../assets/b3.png" class="tuo" />
          <div style="width: 150rpx; margin: 0 auto">历年社保<br />查询</div>
        </router-link>
        <router-link class="item" to="/instrumentdetails?id=10">
          <img src="../assets/b4.png" class="tuo" />
          <div style="width: 150rpx; margin: 0 auto">职称证书<br />下载</div>
        </router-link>
        <router-link class="item" to="/instrumentdetails?id=11">
          <img src="../assets/b5.png" class="tuo" />
          <div style="width: 150rpx; margin: 0 auto">学历验证</div>
        </router-link>
        <router-link class="item" to="/instrumentdetails?id=12">
          <img src="../assets/b6.png" class="tuo" />
          <div style="width: 150rpx; margin: 0 auto">个税清单<br />下载</div>
        </router-link>
        <router-link class="item" to="/instrumentdetails?id=13">
          <img src="../assets/b7.png" class="tuo" />
          <div style="width: 150rpx; margin: 0 auto">积分申请<br />系统</div>
        </router-link>
        <router-link class="item" to="/instrumentdetails?id=14">
          <img src="../assets/b8.png" class="tuo" />
          <div style="width: 150rpx; margin: 0 auto">落户申请<br />系统</div>
        </router-link>
        <router-link class="item" to="/instrumentdetails?id=15">
          <img src="../assets/b9.png" class="tuo" />
          <div style="width: 150rpx; margin: 0 auto">落户状态<br />查询</div>
        </router-link>
      </div>
    </div>
    <div style="height: 1.44rem"></div>
    <tbrs></tbrs>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      img: [],
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  methods: {
    ...mapActions(["getbanner"]),
    tzviewwap() {
      let url = this.img[3].url.split(",");
      if (url[1] == 1) {
        this.$router.push({
          path: "/policydetails",
          query: {
            id: url[0],
          },
        });
      }
      if (url[1] == 2) {
        this.$router.push({
          path: "/traindetails",
          query: {
            id: url[0],
          },
        });
      }
      if (url[1] == 3) {
        this.$router.push({
          path: "/traindetails",
          query: {
            id: url[0],
          },
        });
      }
      if (url[1] == 4) {
        this.$router.push({
          path: "/instrumentdetails",
          query: {
            id: url[0],
          },
        });
      }
    },
  },
  mounted() {
 
    if (this.userinfo == "") {
      this.$dialog
        .confirm({
          title: "",
          message: "为了更好的体验请登录",
        })
        .then(() => {
          this.$router.push({
            path: "/user",
          });
        })
        .catch(() => {});
    }
    this.getbanner({}).then((res) => {
      if (res.SuccessCode == 200) {
        this.img = res.ResponseBody;
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.header_img {
  margin-left: 0.2rem;
  width: 7.1rem;
  height: 1.96rem;
}

.content {
  margin-top: 0.17rem;
  padding: 0 0.2rem;
}

.content .title {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
}

.content .title .d {
  margin-right: 0.1rem;
  transform: translateY(3rpx);
  width: 0.07rem;
  height: 0.28rem;
  background: #458aec;
}

.content .list {
  padding-bottom: 0.5rem;
  margin-top: 0.37rem;
  display: flex;
  flex-wrap: wrap;
  width: 7.04rem;
  box-shadow: 0rem 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  background: #ffffff;
  border-radius: 0.2rem;
}

.content .list .item {
  font-size: 0.26rem;
  width: 33.3%;
  text-align: center;
  margin-top: 0.44rem;
}

.content .list .item .tuo {
  margin: 0 auto;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
}
</style>